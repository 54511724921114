import React, { useEffect } from "react"
import { Layout } from "@/layout"
import { navigate } from "gatsby"

import { Width, Box, SEO, Heading } from "@/components/index"

export default function FbGroup() {
  useEffect(() => {
    setTimeout(() => {
      navigate("https://www.facebook.com/groups/141236114862401")
    }, 3000)
  })
  return (
    <Layout headerType="simple" headerTheme="light" footerTheme="light">
      <SEO title="FB Group" description="Redirecting" />
      <Width
        minheight="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        pt={["16.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box
          height="100%"
          width={["100%", null, 6 / 12]}
          mx="auto"
          pt="6rem"
          pb="9rem"
        >
          <Heading level="1" textAlign="center" mb="2rem">
            Redirecting...
          </Heading>
        </Box>
      </Width>
    </Layout>
  )
}
